


























import {Component, Vue} from 'vue-property-decorator';
import {FRSOStatus} from "@/constants/request/RequestsStatuses";
import FinCENService from "@/services/request/FinCENService";
import FRSO_DTO from "@/dto/request/fincen/frso/FRSO_DTO";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component({
  computed: {
    FRSOStatus() {
      return FRSOStatus
    }
  }
})
export default class CustomerFRSO extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  offer: FRSO_DTO | null = null;

  mounted() {
    this.loadOffer();
  }

  loadOffer() {
    this.startLoading();
    FinCENService.getOfferByPublicId(this.id).then(
        ok => {
          this.offer = ok.data;
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
        }
    );
  }

  changeStatus(status: FRSOStatus) {
    this.offer!.status = status;
    this.startLoading();
    FinCENService.updateOfferByPublicId(this.id, this.offer!).then(
        ok => {
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.offer!.status = FRSOStatus.OPENED;
          this.stopLoading();
        }
    );
  }

  get id(): string {
    return this.$route.params.id;
  }

  get isAccepted(): boolean {
    return [FRSOStatus.ACH, FRSOStatus.PAYMENT_LINK].includes(this.offer!.status);
  }

}
